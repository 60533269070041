import React from "react";
import { useOuterClick } from "../../../../../../../hooks";
import styles from "./LinkMenu.module.css";

export default function LinkMenu({ links, unblur, checked }) {
  const innerRef = useOuterClick((ev) => {
    unblur();
  });

  return (
    <nav className={styles.menu}>
      <input
        checked={checked}
        className={styles.menuToggler}
        type="checkbox"
        onClick={() => unblur()}
        ref={innerRef}
      />
      <label for={styles.menuToggler}></label>

      <ul>
        {links.map((link) => (
          <li
            className={styles.menuItem}
            key={link.title}
            onClick={() => checked && window.open(link.link, undefined, "noreferrer noopener")}
          >
            <a className={styles.title}>{link.title}</a>
          </li>
        ))}
      </ul>
    </nav>
  );
}
