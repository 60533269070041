import React, { useState } from "react";
import styles from "./Onboarding.module.css";

const Onboarding = ({show}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [modalOpen, setModalOpen] = useState(true);
  const steps = [
    {
      title: "Applications",
      content: "Here, you'll find the ability...",
    },
    {
      title: "How-To Resources",
      content: "Next, you'll find the information on...",
    },
    {
      title: "Request Form",
      content: "Next, you can request ReadyOne to personally tell you to...",
    },
    { title: "One last thing...", content: "Blah blah..." },
  ];

  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  if (!modalOpen) {
    return null;
  }

  return (
    <div className={`${show ? styles.modal : styles.hidden}`}>
      <div className={styles.modalContent}>
        <h2>Welcome to ReadyOne!</h2>
        <div className={styles.progressBar}>
          <div
            className={styles.progress}
            style={{ width: `${((currentStep + 1) / steps.length) * 100}%` }}
          ></div>
        </div>
        <div className={styles.step}>
          <h2>{steps[currentStep].title}</h2>
          <p>{steps[currentStep].content}</p>
        </div>
        <div className={styles.btnContainer}>
          <button
            className={styles.nextBtn}
            disabled={currentStep === 0}
            onClick={prevStep}
          >
            Previous
          </button>
          {currentStep === steps.length - 1 ? (
            <button className={styles.nextBtn} onClick={closeModal}>
              Finish
            </button>
          ) : (
            <button className={styles.nextBtn} onClick={nextStep}>
              Next
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
