const apiURL = process.env.REACT_APP_ReadyOneAPIURL;
const apiKey = process.env.REACT_APP_ReadyOneAPIKey;

export function getHowTo_old() {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${apiURL}/gethowto`, requestOptions)
    .then((response) => response.json())
    .then((body) => body.body);
}

export function getTable(tableName) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({ tableName: tableName });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/getTable`, requestOptions).then((response) =>
    response.json()
  );
}

export function sendEmail(Data) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(Data);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/sendEmail`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
}

export function getHowTo() {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  // myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${apiURL}/gets3structure`, requestOptions)
    .then((response) => response.json())
    .then((body) => body.body);
}

export function search(searchTerm) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${apiURL}/search?q=${searchTerm}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result.hits.hits)
    .catch((error) => console.log("error", error));
}

export function searchWorkItems(searchTerm) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${apiURL}/search/work-items?q=${searchTerm}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result.hits.hits)
    .catch((error) => console.log("error", error));
}

export function submitFeedback(feedback) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(feedback);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/submit-feedback`, requestOptions).then((response) =>
    response.json()
  );
}

export function getUserApps(id) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({ id, environment: "ReadyOne_UserApps" });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/get-user-apps`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
}

export function updateUserApps(id, userApps) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    id,
    appList: userApps,
    environment: "ReadyOne_UserApps",
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/update-user-apps`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
}

export function checkIP() {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${apiURL}/check-ip`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
}

export function getNotifications() {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${apiURL}/get-readyone-notifications`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
}

export function postNotification(notification) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(notification);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/get-readyone-notifications`, requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

export function deleteNotification(id) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    id,
  });

  var requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/get-readyone-notifications`, requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

export function removeUserFromList(id, user) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    id,
    user,
    action: "remove",
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/update-user-lists`, requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

export function addUserFromList(id, user) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    id,
    user,
    action: "add",
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/update-user-lists`, requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

export function checkNewUser(username) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    username,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/check-new-login`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
}

export function addDashboard(dashboard) {
  var myHeaders = new Headers();
  myHeaders.append("x-api-key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(dashboard);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/dashboard`, requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

export function deleteDashboard(dashboard) {
  var myHeaders = new Headers();
  myHeaders.append("x-api-key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(dashboard);

  var requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/dashboard`, requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

export function getDashboards() {
  var myHeaders = new Headers();
  myHeaders.append("x-api-key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${apiURL}/dashboard`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
}

export function uploadFile(file, name) {
  try {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", apiKey);
    myHeaders.append("Content-Type", "application/json");


    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    // When the upload file button is clicked,
    // first we need to get the presigned URL
    // URL is the one you get from AWS API Gateway
    return fetch(
      `${apiURL}/upload-readyone-s3/generate-upload-url?fileName=${name}`, requestOptions
    ).then(async (response) => {
      const data = await response.json();
      console.log(data)
      // Getting the url from response
      const url = data.fileUploadURL;

      // Initiating the PUT request to upload file    
      return fetch(url, {
        method: "PUT",
        body: file,
        headers: { "Content-Type": "multipart/form-data" }
      }).then(uploadResponse => {
        if (uploadResponse.ok) {
          return ({
            uploadSuccess: "File upload successful",
            error: undefined
          });
        } else {
          throw new Error("Upload failed");
        }
      })


    })


  } catch (err) {
    return ({
      error: "Error Occurred while uploading the file",
      uploadSuccess: undefined
    });
  }
}

export function getAppStreamURL(accessToken, application) {
  var myHeaders = new Headers();
  console.log(accessToken);
  myHeaders.append("Authorization", accessToken);
  //myHeaders.append("Content-Type", "application/json");
  let body = {
    application: application.appName,
    fleet: application.fleet,
    stack: application.stack
  }

  let raw = JSON.stringify(body);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
  };

  return fetch(
    `${apiURL}/get-appstream`,
    requestOptions
  ).then((response) => response.json());
}


export function getIncidentFields() {
  const myHeaders = new Headers();
  myHeaders.append("x-api-key", apiKey);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  return fetch(`${apiURL}/get-incident-fields`, requestOptions)
    .then((response) => response.json());
}

export function postIncident(payload) {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("x-api-key", apiKey);

  const raw = JSON.stringify(convertIncidentPayload(payload));

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };

  return fetch(`${apiURL}/get-incident-fields`, requestOptions).then((response) => response.text());
}

// const [selectedValues, setSelectedValues] = useState({
//   server: '',
//   database: '',
//   engineeringDomain: '',
//   category: '',
//   application: '',
//   issue: '',
//   severity: '',
//   priority: '',
//   description: '',
// });


function convertIncidentPayload(payload) {
  const description = `${payload.description} \r\nSubmitted by ${payload.email}`
  return {
    active_database: payload.database,
    classification: `${payload.category}/${payload.application}`,
    comment_description: description,
    created_by_email: payload.email,
    problem_description: description,
    secondary_classification: payload.issue,
    server: payload.server,
    severity: payload.severity,
    priority: payload.priority
  }
}