import React, { useState, useContext, useEffect } from "react";
import styles from "./TestButton.module.css";
import { Title, Footer, Video } from "../Applications/Layout";
import DissolveBox from "./Button";

const TestButton = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };
  //   I'm thinking with the 'options' below we should be able to set it up to intake from the db
  const options = ["Production", "Testing", "Development", "DevCom"];

  return (
    <div className={styles.gettingStartedWholeBox}>
      <Title
        title="Applications"
        subtitle="Select an application to get started"
      />

      {/* This is how it would be imported into the app drawer, though I'm sure that may change with connecting it to the db */}
      <DissolveBox options={options} />

      <Footer
        title="Getting started"
        body="In this video, you will find some useful information on getting
                started with App Streams. This video demonstrates launching
                engineering applications within your web browser, and navigating
                the App Streams toolbar. If you need additional help, click the
                question mark on the bottom left to ask any question you may
                have."
      >
        <Video url="https://www.youtube.com/watch?v=oafbd7jsYAg" />
      </Footer>
    </div>
  );
};

export default TestButton;
