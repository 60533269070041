
import React, { useContext, useEffect } from "react";
import { Layout, Background, ToastProvider } from "./layout";
import { AccountContext, Login } from "./auth";
import {
  Applications,
  HowTo,
  RequestForm,
  PTBPage,
  DemoPage,
  Dashboards,
  FileUpload,
  TestButton,
} from "./pages";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {

  const { authenticated } = useContext(AccountContext);

  useEffect(() => {
    console.log(process.env.REACT_APP_ReadyOneAPIKey)
  },[])

  return (
    <div className="App">
      <Router>
        {authenticated ? (
          <ToastProvider>
            <Layout>
              <Routes>
                <Route path="/" element={<Applications />} />
                <Route exact path="/Applications" element={<Applications />} />
                <Route exact path="/How-To-Resources" element={<HowTo />} />
                <Route exact path="/Request-Form" element={<RequestForm />} />
                <Route path="/Demo" exact element={<DemoPage />} />
                <Route path="/ptb-challenge" exact element={<PTBPage />} />
                <Route path="/Dashboards" exact element={<Dashboards />} />
                <Route path="/TestButton" exact element={<TestButton />} />
              </Routes>
            </Layout>
          </ToastProvider>
        ) : (
          <>
            <div>
              <Routes>
                <Route path="/Demo" exact element={<DemoPage />} />
                <Route path="/ptb-challenge" exact element={<PTBPage />} />
                <Route path="/upload" exact element={<FileUpload />} />
                <Route path="*" exact element={<Login />} />
              </Routes>
              <Background />
            </div>
          </>
        )}
      </Router>
    </div>
  );
}

export default App;
