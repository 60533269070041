import React, { useEffect, useState, useRef, useContext } from "react";
import styles from "./Account.module.css";
import { AccountContext } from "../../../auth";
import { ToastContext } from "../../../layout";
import { useNavigate } from "react-router-dom";
import {
  BsPower,
  BsKanban,
  BsBezier2,
  BsChatRightText,
  BsFilePerson,
} from "react-icons/bs";
import { BiLogOutCircle, BiAddToQueue } from "react-icons/bi";
import { useOnClickOutside } from "../../../hooks";
import { makeELMOAuthRequests } from "../../../services/ELMAdminAPI";
import Modal from "./Modal/Modal";
import NotificationForm from "./NotificationForm/NotificationForm";
import DashboardForm from "./DashboardForm/DashboardForm";
import UserLists from "./UserLists/UserLists";

export default function Account({ show, close }) {
  const {
    setAuthenticated,
    email,
    firstName,
    lastName,
    username,
    logout,
    opshub,
    dashboard,
    notification,
  } = useContext(AccountContext);

  const [hasRendered, setHasRendered] = useState(false);
  const [showToken, setShowToken] = useState(false);
  const [token, setToken] = useState("");
  const [secret, setSecret] = useState("");
  const [showNotif, setShowNotif] = useState(false);
  const [showUserLists, setShowUserLists] = useState(false);
  const [showAddDashboard, setShowAddDashboard] = useState(false);
  const ref = useRef(null);

  const { notifyInfo, dismiss, delay } = useContext(ToastContext);
  const showOpsHub = opshub.includes(username);
  const showNotification = notification.includes(username);
  const showDashboardAdmin = dashboard.includes(email);
  const navigate = useNavigate();
  useOnClickOutside(ref, () => show && close());

  useEffect(() => {
    console.log(notification);
    console.log(username);
  }, []);

  useEffect(() => {
    if (show) {
      dismiss();
      setHasRendered(true);
    }
  }, [show]);

  function AccountItem({ name, email }) {
    return (
      <li className={styles.accountItem}>
        <span>{name}</span>
        <a>{email}</a>
      </li>
    );
  }

  function Item({ id, onClick, leftIcon, rightIcon, children }) {
    return (
      <a id={id} className={styles.item} onClick={onClick}>
        <span>{leftIcon}</span>
        {children}
        <span>{rightIcon}</span>
      </a>
    );
  }

  return (
    <>
      <div
        ref={ref}
        className={`${styles.dropdown} ${show ? styles.open : ""} ${
          hasRendered ? "" : styles.preventAnimation
        }`}
      >
        <ul className={styles.list}>
          <AccountItem name={`${firstName} ${lastName}`} email={email} />
          {showOpsHub && (
            <Item
              id="opshub-button"
              leftIcon={<BsBezier2 />}
              onClick={() => {
                makeELMOAuthRequests().then((result) => {
                  console.log(result);
                  setToken(result.oauthToken);
                  setSecret(result.oauthTokenSecret);
                  setShowToken(true);
                });
              }}
            >
              Generate OpsHub Token
            </Item>
          )}
          {showNotification && (
            <>
              <Item
                id="notification-button"
                leftIcon={<BsChatRightText />}
                onClick={() => {
                  setShowNotif(true);
                }}
              >
                Create Notification
              </Item>
              <Item
                id="notification-button"
                leftIcon={<BsFilePerson />}
                onClick={() => {
                  setShowUserLists(true);
                }}
              >
                Edit User Lists
              </Item>
            </>
          )}
          {showDashboardAdmin && (
            <>
              <Item
                id="logoff-button"
                leftIcon={<BsKanban />}
                onClick={() => {
                  setShowAddDashboard(true)
                  close();
                }}
              >
                Edit Dashboards
              </Item>
            </>
          )}

          <Item
            id="logoff-button"
            leftIcon={<BiLogOutCircle />}
            onClick={() => {
              close();
              notifyInfo("Logging Off...", 1000);
              delay(1500).then(() => {
                navigate("/");
                logout(setAuthenticated);
              });
            }}
          >
            Log Off
          </Item>
        </ul>
      </div>
      {showOpsHub && (
        <Modal
          id="ops-hub-modal"
          show={showToken}
          title={"OpsHub ELM Token"}
          buttonText={"Open OpsHub"}
          onClick={() => {
            window.open("https://globalhawk.readyone.net:8443/OIM", "_blank");
            window.open("https://sentinel.readyone.net:8443/OIM", "_blank");
          }}
          hideModal={() => setShowToken(false)}
        >
          <ul>
            <li>
              <b>OAuth Token: </b>
              {token}
            </li>
            <li>
              <b>OAuth Token Secret Key: </b>
              {secret}
            </li>
          </ul>
        </Modal>
      )}

      {showNotification && (
        <NotificationForm show={showNotif} close={() => setShowNotif(false)} />
      )}
      {showUserLists && (
        <UserLists show={showUserLists} close={() => setShowUserLists(false)} />
      )}

      {showDashboardAdmin && (
        <DashboardForm
          show={showAddDashboard}
          close={() => setShowAddDashboard(false)}
        />
      )}
    </>
  );
}
