import React, { useState, useEffect, useContext } from 'react';
import styles from './RequestForm.module.css';
import { getIncidentFields, postIncident } from '../../services/ReadyOneAPI';  // Assuming this function is available
import { AccountContext } from '../../auth';
import { Account } from 'aws-sdk';

const RequestForm = () => {
  const {email} = useContext(AccountContext);

  const [formData, setFormData] = useState({
    servers: [],
    databases: [],
    engineeringDomains: [],
    categories: [],
    severity: [],
    priority: [],
    rawData: []
  });

  const [selectedValues, setSelectedValues] = useState({
    server: '',
    database: '',
    engineeringDomain: '',
    category: '',
    application: '',
    issue: '',
    severity: '',
    priority: '',
    description: '',
    email: email
  });
  

  const [filteredApplications, setFilteredApplications] = useState([]);
  const [filteredIssues, setFilteredIssues] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const data = await getIncidentFields();
      
      // Extract unique categories from data
      const categories = [...new Set(data.categories.map(item => item.domain.split('/')[0]))];
      
      setFormData({
        servers: data.servers,
        databases: data.databases,
        engineeringDomains: data.engineeringDomains,
        categories: categories,
        severity: data.severity,
        priority: data.priority,
        rawData: data.categories // Store raw data for dynamic filtering
      });
      
      setLoading(false); // Set loading to false after data is fetched
    }
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedValues({ ...selectedValues, [name]: value });
    console.log(`Name: ${name}`)
    console.log(`Value: ${value}`)
    console.log(`selectedValues: ${JSON.stringify(selectedValues)}`)
    if (name === 'category') {
      // Filter applications based on selected category
      const filteredApps = formData.rawData
        .filter((item) => item.domain.split('/')[0] === value)
        .map((item) => item.domain.split('/')[1]);

        console.log(`filtered Apps: ${filteredApps}`)
      setFilteredApplications([...new Set(filteredApps)]); // Unique applications
      setSelectedValues({ ...selectedValues, application: '', issue: '' }); // Reset application and issue
      setSelectedValues({ ...selectedValues, [name]: value });
      setFilteredIssues([]); // Reset issues when category is changed
    }

    if (name === 'application') {
      // Filter issues based on selected category and application
      const filteredIss = formData.rawData
        .filter(
          (item) =>
            item.domain.split('/')[0] === selectedValues.category && item.domain.split('/')[1] === value
        )
        .map((item) => item.request);
      setFilteredIssues(filteredIss);
      setSelectedValues({ ...selectedValues, issue: '' }); // Reset issue
      setSelectedValues({ ...selectedValues, [name]: value });
    }
  };

  const onSubmit = () => {
    postIncident(selectedValues).then(result => console.log(result))
  }

  if (loading) {
    return <div className={styles.loading}>Loading...</div>; // Loading indicator
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Incident Form</h2>

      {/* Server Dropdown */}
      <label className={styles.label}>Server:</label>
      <select name="server" value={selectedValues.server} onChange={handleChange} className={styles.select}>
        <option value="">Select a server</option>
        {formData.servers.map((server, index) => (
          <option key={index} value={server}>{server}</option>
        ))}
      </select>

      {/* Database Dropdown */}
      <label className={styles.label}>Database:</label>
      <select name="database" value={selectedValues.database} onChange={handleChange} className={styles.select}>
        <option value="">Select a database</option>
        {formData.databases.map((database, index) => (
          <option key={index} value={database}>{database}</option>
        ))}
      </select>

      {/* Engineering Domain Dropdown */}
      <label className={styles.label}>Engineering Domain:</label>
      <select
        name="engineeringDomain"
        value={selectedValues.engineeringDomain}
        onChange={handleChange}
        className={styles.select}
      >
        <option value="">Select an engineering domain</option>
        {formData.engineeringDomains.map((domain, index) => (
          <option key={index} value={domain}>{domain}</option>
        ))}
      </select>

      {/* Category Dropdown */}
      <label className={styles.label}>Category:</label>
      <select
        name="category"
        value={selectedValues.category}
        onChange={handleChange}
        className={styles.select}
      >
        <option value="">Select a category</option>
        {formData.categories.map((category, index) => (
          <option key={index} value={category}>
            {category}
          </option>
        ))}
      </select>

      {/* Application Dropdown */}
      <label className={styles.label}>Application:</label>
      <select
        name="application"
        value={selectedValues.application}
        onChange={handleChange}
        className={styles.select}
        disabled={!selectedValues.category} // Disable until category is selected
      >
        <option value="">Select an application</option>
        {filteredApplications.map((application, index) => (
          <option key={index} value={application}>
            {application}
          </option>
        ))}
      </select>

      {/* Issue Dropdown */}
      <label className={styles.label}>Issue:</label>
      <select
        name="issue"
        value={selectedValues.issue}
        onChange={handleChange}
        className={styles.select}
        disabled={!selectedValues.application} // Disable until application is selected
      >
        <option value="">Select an issue</option>
        {filteredIssues.map((issue, index) => (
          <option key={index} value={issue}>
            {issue}
          </option>
        ))}
      </select>

      {/* Severity Dropdown */}
      <label className={styles.label}>Severity:</label>
      <select name="severity" value={selectedValues.severity} onChange={handleChange} className={styles.select}>
        <option value="">Select severity</option>
        {formData.severity.map((severity, index) => (
          <option key={index} value={severity}>{severity}</option>
        ))}
      </select>

      {/* Priority Dropdown */}
      <label className={styles.label}>Priority:</label>
      <select name="priority" value={selectedValues.priority} onChange={handleChange} className={styles.select}>
        <option value="">Select priority</option>
        {formData.priority.map((priority, index) => (
          <option key={index} value={priority}>{priority}</option>
        ))}
      </select>

      <label htmlFor="description" className={styles.label}>Description:</label>
      <textarea
        id="description"
        name="description"
        onChange={handleChange}
        className={styles.textarea}
        placeholder="Enter a detailed description"
      />
      <button className={styles.button} onClick={onSubmit}>Submit</button>
    </div>
  );
};

export default RequestForm;
