import React, { useState } from "react";
import styles from "./TestButton.module.css";
import { BsXLg } from "react-icons/bs";

// This is the component itself
// Just a few things to note, we can change anything about this component. Font size, background color, box size, etc. It's pretty maluable.
// Getting rid of the white background also makes the image file size substantially smaller, all things considered.
// With the white, the Aras logo is 90 kb. With the new button, its 18 kb. So would help make the app at least a little more lightweight.
// All apps with white is roughly 1.5 Mb, without the white it is roughly 275 kb (Estimation based off Aras logo).
// Won't make a huge difference but still thought 1.3 Mb difference was neat lol

const DissolveBox = ({ options }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className={styles.container}>
      <div
        className={`${styles.dissolveBox} ${isOpen ? styles.open : ""}`}
        onClick={!isOpen ? toggleOpen : undefined}
      >
        {isOpen ? (
          <div className={styles.optionsContainer}>
            {options.map((option, index) => (
              <div key={index} className={styles.option}>
                {option}
              </div>
            ))}
            <BsXLg className={styles.closeBtn} onClick={toggleOpen}></BsXLg>
          </div>
        ) : (
          <div className={styles.mainBox} />
        )}
      </div>
      <div className={styles.appTitle}>iOS icon go brrr</div>
    </div>
  );
};

export default DissolveBox;
